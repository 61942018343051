$(document).ready(function(){
  //verifica o browser e versão
  function get_browser_info(){
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1]||'')};
        }
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
  }

  var browser=get_browser_info();
  //alert(browser.name + " " + browser.version);
  //alert(browser.version);
  if(browser.name == "Safari" && browser.version < 6){
    // function sidebarContent() {
    //   var sidebarContent= $(".sidebar-main").get();
    //   if($(window).width() < 480){
    //     $(".sidebar-main").remove();
    //     $(".content-main").before(sidebarContent);
    //   }
    // }

    // $( window ).resize(function() {
    //   sidebarContent();
    // });

    var appendCssSafari = '<link rel="stylesheet" href="css/safari.css">';
    $('head').append(appendCssSafari);
    //alert(browser.name);
    //alert(browser.version);
    if(browser.name == "Safari" && browser.version < 5){

     $('header.sonhos-header').append('<div class="mdl-layout__drawer-button">&#9776;</div>');
     $('.sonhos-drawer').append('<a class="close-menu-fix">&#215;</a>');

     $('.close-menu-fix').on("click",function(){
       //alert("passou");
       $('.sonhos-drawer').css('transform', 'translateX(-250px)');
       //$('.mdl-layout__obfuscator').css('visibility', 'hidden');
     });

     $('.mdl-layout__drawer-button').on("click",function(){
       $('.sonhos-drawer').css('transform', 'translateX(0)');
       //$('.mdl-layout__obfuscator').css('visibility', 'visible');
     });

     $('.material-icons').each(function(){
       var iconType = $(this).text();
       if(iconType == 'search'){$(this).text('🔍')}
       if(iconType == 'more_vert'){$(this).text('⋮')}
     });
   }
  }

  if(browser.name == "Safari" && browser.version < 9){
    function sidebarContent() {
      var sidebarContent= $(".sidebar-main").get();
      if($(window).width() < 480){
        $(".sidebar-main").remove();
        $(".content-main").before(sidebarContent);
      }
    }
    sidebarContent();
  }

  if(browser.name == "MSIE" && browser.version < 10){

   //var appendCssSafari = '<div class="mdl-layout__drawer-button">&#9776;</div>';
   $('header.sonhos-header').append('<div class="mdl-layout__drawer-button">&#9776;</div>');
   $('.sonhos-content').after('<div class="mdl-layout__obfuscator"></div>');
   $('.mdl-layout__drawer-button').on("click",function(){
     $('.sonhos-drawer').css('-ms-transform', 'translateX(0)');
     $('.mdl-layout__obfuscator').css('visibility', 'visible');
   });

   $('.mdl-layout__obfuscator').on("click",function(){
     $('.sonhos-drawer').css('-ms-transform', 'translateX(-250px)');
     $(this).css('visibility', 'hidden');
   });

   $('.material-icons').each(function(){
     var iconType = $(this).text();
     if(iconType == 'search'){$(this).text('🔍')}
     if(iconType == 'more_vert'){$(this).text('⋮')}
   });
  }
  //FIM verifica o browser e versão

  //manipulando o mega menu
  $(".show-sub-menu").each(function(){
    $(this).hover(
      function(){
        var currentMenu = $(this).attr("data-menu");
        $(".show-sub-menu, #mdl-navigation-mega-menu li").removeClass("active"); // retira active de todos
        $("."+currentMenu).addClass("active"); //mega menu atual
        $(".show-sub-menu[data-menu="+ currentMenu +"]").addClass("active"); //add active para não perder a marcação
        //alert(currentMenu);
        $("."+currentMenu).hover(
          function() {
            $("."+currentMenu).addClass("active");
            $(".show-sub-menu[data-menu="+ currentMenu +"]").addClass("active");
          },
          function() {
            $("."+currentMenu).removeClass("active");
            $(".show-sub-menu, #mdl-navigation-mega-menu li").removeClass("active");
        });
      },
      function() {
        $(this).removeClass("active");
        $(".show-sub-menu, #mdl-navigation-mega-menu li").removeClass("active");
      });
  });

  if($("#mini-gal-card").length > 0) {
    $("#mini-gal-card").owlCarousel({
      items: 4,
      pagination: false,
      navigation: true,
      navigationText: ["",""],
      itemsTablet: [520,2],
      itemsMobile : [380,1]
    });
  }

  //close messages
  $(".close-box-message").on("click", function(){
    $(this).parent(".box-message").slideUp(function(){
      $(this).remove();
    });
  });

  //manipulando a busca no mobile para esconder o logo se houver texto no input
  if ($(".mdl-textfield__input").length > 0) {
    var inputFieldSearch = $(".mdl-textfield__input").val();
    if (inputFieldSearch.length > 0){$(".header-app-sonhos__logo").addClass("hide");}
    $(".mdl-textfield__input").on("blur", function(){
      var inputFieldSearch = $(".mdl-textfield__input").val();
      if (inputFieldSearch.length > 0){
        $(".header-app-sonhos__logo").addClass("hide");
      }else{
        $(".header-app-sonhos__logo").removeClass("hide");
      }
    });
  }
});
